




































































































import { Vue, Component, Prop } from 'vue-property-decorator'
import { vxm } from '@/store'
import TyreHotelComment from '@/models/tyrehotel/TyreHotelComment'
import { formatDateTimeString } from '@/utils/dateUtils'

@Component({
  methods: { formatDateTimeString },
})
export default class CommentData extends Vue {
  @Prop({ type: Number, required: true }) private tyreHotelId!: number

  $refs!: Vue['$refs'] & {
    sendCommentForm: { validate: () => boolean; reset: () => void }
  }

  private page = 1
  private hasMoreComments = true

  private commentData: TyreHotelComment[] = []
  private addDialog = false
  private deleteDialog = false
  private commentToAdd = new TyreHotelComment()
  private commentToDelete = new TyreHotelComment()

  private requiredRule = (v: string) => !!v || 'Field is required'

  async created() {
    await this.fetchComments()
  }

  private toggleDeleteDialog(comment) {
    this.commentToDelete = comment
    this.deleteDialog = true
  }

  private async fetchComments() {
    await this.$axios
      .get(`/v4/site/tyre-hotels-v2/comment/${this.tyreHotelId}?page=${this.page}&perPage=5`)
      .then((response) => {
        const newComments = response.data.data

        this.commentData = this.page === 1 ? newComments : [...this.commentData, ...newComments]
        this.hasMoreComments = newComments.length === 5
      })
      .catch((error) => {
        console.error('Error fetching comments:', error)
      })
  }

  private async saveComment() {
    if (!this.$refs.sendCommentForm.validate()) return

    this.commentToAdd.tyreHotelId = this.tyreHotelId

    await this.$axios
      .post('/v4/site/tyre-hotels-v2/comment', this.commentToAdd)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved'),
          title: this.$t('c:common:Success'),
        })
        this.addDialog = false
        this.commentToAdd = new TyreHotelComment()
        this.fetchComments()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error saving tyre hotel comment')
      })
  }

  private async deleteComment() {
    await this.$axios
      .delete(`/v4/site/tyre-hotels-v2/comment/${this.commentToDelete.id}`)
      .then(async() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully deleted'),
          title: this.$t('c:common:Success'),
        })
        this.deleteDialog = false
        await this.fetchComments()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error deleting tyre hotel comment')
      })
  }

  private loadMoreComments() {
    if (this.hasMoreComments) {
      this.page += 1
      this.fetchComments()
    }
  }
}
