export default class TyreHotelComment {
  public id: number
  public comment: string
  public tyreHotelId: number
  public userEmail: string
  public createdAt: Date

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.id = data.id ? (data.id as number) : null
    this.comment = data.comment ? (data.comment as string) : null
    this.tyreHotelId = data.tyreHotelId ? (data.tyreHotelId as number) : null
    this.userEmail = data.userEmail ? (data.userEmail as string) : null
    this.createdAt = data.createdAt ? (data.createdAt as Date) : null
  }
}
