















































import { Vue, Component, Prop } from 'vue-property-decorator'
import TyreHotel from '@/models/tyrehotel/TyreHotel'
import { vxm } from '@/store'

@Component({})
export default class NoteData extends Vue {
  @Prop({ type: TyreHotel, required: true })
  private tyreHotel: TyreHotel

  private loading = false

  private saveNotes(): void {
    this.loading = true
    this.$axios
      .post('/v4/site/tyre-hotels-v2/' + this.tyreHotel.id + '/update-note', this.tyreHotel)
      .then(() => {
        vxm.alert.success({
          content: this.$t('c:common:Successfully saved') as string,
          title: this.$t('c:common:Success') as string,
        })
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Error on saving tyre hotel notes')
      })
      .finally(() => {
        this.loading = false
      })
  }
}
